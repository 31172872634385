@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        @apply bg-primary font-serif text-on-primary
    }
}

@layer utilities {
    .content {
        @apply w-full md:w-128 mx-auto
    }

    .title {
        @apply text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-snug md:leading-snug lg:leading-snug xl:leading-snug font-serif
    }

    .title-lg {
        @apply text-3xl md:text-4xl lg:text-5xl xl:text-6xl leading-snug md:leading-snug lg:leading-snug xl:leading-snug font-serif
    }

    .title-xl {
        @apply text-4xl md:text-5xl lg:text-6xl xl:text-7xl leading-snug md:leading-snug lg:leading-snug xl:leading-snug font-serif
    }

    .subtitle {
        @apply text-lg md:text-xl lg:text-2xl xl:text-3xl leading-snug md:leading-snug lg:leading-snug xl:leading-snug
    }

    .body {
        @apply text-sm md:text-base lg:text-lg xl:text-xl leading-snug md:leading-snug lg:leading-snug xl:leading-snug font-light
    }

    .note {
        @apply text-xs md:text-sm lg:text-base xl:text-lg leading-snug md:leading-snug lg:leading-snug xl:leading-snug font-light
    }

    .btn {
        @apply px-4 py-3 rounded hover:backdrop-contrast-100 border border-on-primary bg-on-primary text-primary flex items-center justify-center text-sm md:text-base
    }

    .btn-lg {
        @apply rounded-lg text-lg py-4 px-6
    }

    .btn-twitter {
        @apply border-twitter bg-twitter text-white
    }

    .btn-discord {
        @apply border-discord bg-discord text-white
    }

    .btn-outline {
        @apply bg-transparent text-on-primary
    }

    .btn-clear {
        @apply bg-transparent text-on-primary border-none hover:backdrop-contrast-75
    }

    .input {
        @apply w-full border border-on-primary rounded leading-tight py-2 px-3 text-black
    }

    .input-lg {
        @apply rounded-lg text-2xl py-4 px-6
    }

    .select {
        @apply appearance-none block py-3 px-4 w-full rounded bg-primary border border-sm border-on-primary text-lg text-on-primary bg-blend-screen
    }

    .select-lg {
        @apply rounded-lg text-2xl py-4 px-6 border-md
    }

    .web3modal-modal-lightbox {
        z-index: 100 !important;
    }
}